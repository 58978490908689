<template>
  <div class="r-table">
    <div class="r-table__wrapper">
      <table
        v-if="columns && data.length > 0 && !loading"
        :class="['r-table__table', { sorting, 'fixed-first': fixedFirstCol }]"
      >
        <thead
          v-if="!noHead"
          @[handler]="getSort($event)"
        >
          <tr>
            <th
              v-if="numeric"
              data-col="tOrder"
            >
              <div class="r-table__head">
                <r-title type="subtitle-2"> № </r-title>
                <r-icon
                  name="sort-ascending"
                  :rotate="getIconRotate('tOrder')"
                  :color="getColor('tOrder')"
                  class="r-table__icon"
                  :size="20"
                />
              </div>
            </th>
            <th
              v-for="({ title = '', type = 'default' }, key) in columns"
              :key="key"
              :data-col="key"
              :data-type="type"
            >
              <div class="r-table__head">
                <r-title
                  type="subtitle-2"
                  :title="title"
                >
                  {{ title }}
                </r-title>
                <r-icon
                  v-if="sorting && !hasSlot(key)"
                  name="sort-ascending"
                  :rotate="getIconRotate(key)"
                  :color="getColor(key)"
                  class="r-table__icon"
                  :size="20"
                />
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(row, ndx) in tableData"
            :key="ndx"
            :class="{ selected: selected === row.id }"
            @click="$emit('row-click', row)"
          >
            <td v-if="numeric">
              <r-text>
                {{ row.tOrder }}
              </r-text>
            </td>
            <td
              v-for="({ width, hardWidth, type = 'default' }, key) in columns"
              :key="key"
              :style="getTdStyle(width, hardWidth)"
            >
              <slot
                v-if="hasSlot(key)"
                :row="row"
                :name="key"
              />
              <r-text
                v-else
                class="r-table__cell"
                :title="getValueByType(row[key], type)"
              >
                {{ getValueByType(row[key], type) }}
              </r-text>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        v-else
        class="r-table__no-data"
      >
        <r-text> {{ loading ? '' : $t('no-data') }} </r-text>
      </div>
    </div>
  </div>
</template>

<script>
import { getValueByType } from 'HELPERS'
export default {
  props: {
    data: {
      type: Array,
      required: true
    },
    columns: {
      type: Object,
      default: null
    },
    sorting: {
      type: Boolean,
      default: false
    },
    initSort: {
      type: String,
      default: ''
    },
    noHead: {
      type: Boolean,
      default: false
    },
    numeric: {
      type: Boolean,
      default: false
    },
    selected: {
      type: [Number, String],
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    fixedFirstCol: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      sortingCol: null
    }
  },
  computed: {
    tableData() {
      const tableData = this.data.map((item, ndx) => ({
        ...item,
        tOrder: ndx + 1
      }))

      if (!this.initSort) return tableData

      const collator = new Intl.Collator('ru-RU', { numeric: true })
      const { initSort } = this
      tableData.sort((a, b) => collator.compare(a[initSort], b[initSort]))

      return tableData
    },
    handler() {
      return this.sorting ? 'click' : null
    }
  },
  methods: {
    hasSlot(key) {
      return Object.keys(this.$scopedSlots).includes(key)
    },
    getIconRotate(title) {
      if (!this.sortingCol) return 0
      const { col, direction } = this.sortingCol

      return col === title && direction === 1 ? 0 : col === title ? 180 : 0
    },
    getColor(title) {
      if (!this.sortingCol) return 'var(--dividers-high-contrast)'
      const { col } = this.sortingCol

      return col === title
        ? 'var(--accent-primary)'
        : 'var(--dividers-high-contrast)'
      // FIXME: replace by css classes
    },
    getTdStyle(width, hardWidth) {
      if (width) {
        return hardWidth ? { width: `${width}px` } : { minWidth: `${width}px` }
      } else {
        return {}
      }
    },
    getSort({ target }) {
      const thTarget = target.closest('th')

      if (!thTarget) return
      const direction = (thTarget.dataset.direction = -(
        thTarget.dataset.direction || -1
      ))
      const col = thTarget.dataset.col
      const type = thTarget.dataset.type
      const collator = new Intl.Collator('ru-RU', { numeric: true })

      this.tableData.sort((a, b) => {
        const next = type === 'date' ? Date.parse(a[col]) : a[col]
        const cur = type === 'date' ? Date.parse(b[col]) : b[col]

        return direction * collator.compare(next, cur)
      })
      this.sortingCol = { col, direction }
    },
    getValueByType
  }
}
</script>

<style lang="scss" scoped>
.r-table {
  flex-direction: column;
  display: flex;
  height: 100%;
  width: 100%;

  &__wrapper {
    overflow: auto;
    flex-grow: 1;
    height: 100%;
    width: 100%;
  }

  &__table {
    border-collapse: separate;
    border-spacing: 0;
    max-height: 100%;
    width: 100%;
    text-align: left;

    &.sorting {
      thead {
        cursor: pointer;
      }
    }

    &.fixed-first {
      thead tr > :first-child {
        z-index: 3;
        left: 0;
        top: 0;
        @include border-right;
      }

      tbody tr {
        & > :first-child {
          position: sticky;
          z-index: 1;
          left: 0;
          @include border-right;
          background-color: $bg-panel-secondary;
        }
      }
    }
  }

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.25rem;
  }

  &__icon {
    flex-shrink: 0;
  }

  &__cell {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  th {
    background-color: $text-tertiary;
    height: 40px;
    padding: 0.25rem 0.5rem;
    user-select: none;
    border: 1px solid $table-border;
    border-right-color: transparent;
    position: sticky;
    z-index: 2;
    top: 0;

    .r-title {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    div {
      word-break: normal;
    }
  }

  td {
    padding: 0.25rem 0.5rem;
    border: 1px solid $table-border;
    border-top-color: transparent;
    border-right-color: transparent;

    div {
      word-break: normal;
    }
  }

  tr > :last-child {
    border-right: 1px solid $table-border;
  }

  tr.selected {
    background-color: $accent-primary-1;
  }

  &__no-data {
    text-align: center;
    padding: 1rem;
  }
}
</style>
