<template>
  <div
    class="r-select"
    :class="{
      disabled,
      error,
      'dropdown-style': dropdownStyle,
      'show-all': showAllSelectedItems,
      opened: isMenuOpened
    }"
  >
    <r-text
      v-if="label"
      color-type="subhead"
    >
      {{ label }}
    </r-text>

    <div
      ref="wrapper"
      class="r-select__input-wrapper"
      tabindex="0"
      :class="{ angular }"
      :style="{ maxWidth: maxWidth, width: `${width}px` }"
      @click="inputClickHandler"
      @mouseenter="isClearButtonShowing = true"
      @mouseleave="isClearButtonShowing = false"
    >
      <div
        v-if="!hasNoTextData"
        :class="[
          'r-select__selected-items',
          { 'r-select__selected-items--no-crop': !cropMultipleSelected }
        ]"
      >
        <r-text
          v-for="(id, i) in showedItems"
          :key="i"
          :size="12"
          class="r-select__selected-item"
        >
          <span class="r-select__selected-item-text">
            {{ getOptionTitleById(id) }}
          </span>

          <r-icon
            class="r-select__selected-delete"
            name="close-delete"
            :size="15"
            @click.stop.native="deleteSelectedItem(id)"
          />
        </r-text>

        <!-- Active items counter -->
        <div
          v-if="multiple && active?.length > 2 && !showAllSelectedItems"
          class="r-select__selected-item"
        >
          <r-text
            class="r-select__selected-item-more"
            :size="12"
            color-type="primary"
          >
            {{ `+${active?.length - 2}` }}
          </r-text>
        </div>

        <!-- Label -->
        <r-text
          v-if="cacheLabel"
          class="r-select__text-ellipsis"
        >
          {{ cacheLabel }}
        </r-text>

        <!-- Placeholder -->
        <r-text
          v-else-if="placeholder && !active?.length"
          class="r-select__text-ellipsis"
          color-type="field-placeholder"
        >
          {{ placeholder }}
        </r-text>
      </div>

      <render-option
        v-else-if="selectedOption"
        :dom="selectedOption.html[0].componentOptions.children"
      />

      <r-button
        v-if="loading"
        class="r-select__clear-button"
        loading
        simple
        mini
      />

      <r-button
        v-else-if="isClearButton"
        simple
        class="r-select__clear-button"
        mini
        icon="clear-input"
        @click.native.stop="clearButtonClickHandler"
      />

      <r-button
        v-else
        tabindex="-1"
        class="r-select__dropdown-icon"
        :icon="{ name: dropdownStyle ? '' : 'chevron-down', size: 16 }"
        :dropdown="dropdownStyle"
        mini
        simple
        :disabled="disabled"
        :type="dropdownStyle ? 'primary' : 'default'"
        @click.native.stop="toggleMenu"
      />
    </div>

    <portal
      v-if="isMenuOpened"
      to="main-portal"
    >
      <transition
        name="unroll"
        mode="in-out"
      >
        <select-menu
          ref="menu"
          :key="menuTop"
          :loading="loading"
          :top="menuTop"
          :bottom="menuBottom"
          :left="menuLeft"
          :width="menuWidth"
          :filtered-options="filteredOptions"
          :multiple="multiple"
          :active="active"
          :mode="mode"
          :has-no-text-data="hasNoTextData"
          :no-select-all="noSelectAll"
          :service-options="!!serviceHandler"
          :cache-query="cacheQuery"
          :text="text"
          :disabled="disabled"
          :is-filterable="isFilterable"
          :total-count="totalCount"
          :cache-endpoint-options="cacheEndpointOptions"
          :cache-display-options="cacheDisplayOptions"
          :qty-page="qtyPage"
          @item-click="itemClickHandler"
          @select-all="selectAll"
          @filter-input="filterInputHandler"
          @show-more="showMoreHandler"
        />
      </transition>
    </portal>
  </div>
</template>

<script>
import { cloneDeep, debounce } from 'HELPERS'
import eventBus, { eventNames } from 'EVENT_BUS'
import i18n from '@/extensions/i18n'
const { SELECT_OPEN } = eventNames

export default {
  components: {
    selectMenu: () => import('./r-select/components/select-menu'),
    renderOption: () => import('./r-select/components/render-option')
  },
  model: {
    prop: 'selected',
    event: 'change'
  },
  props: {
    options: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: i18n.t('select_item')
    },
    selected: {
      type: [String, Array, Number],
      default: null
    },
    filterable: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    angular: {
      type: Boolean,
      default: false
    },
    dropdownStyle: {
      type: Boolean,
      default: false
    },
    showAllSelectedItems: {
      type: Boolean,
      default: false
    },
    maxWidth: {
      type: String,
      default: '100%'
    },
    label: {
      type: String,
      default: ''
    },
    width: {
      type: Number,
      default: null
    },
    mode: {
      // ellipsis, tooltip, wrap
      type: String,
      default: 'ellipsis'
    },
    cropMultipleSelected: {
      type: Boolean,
      default: true
    },
    serviceHandler: {
      type: Function,
      default: null
    },
    step: {
      type: Number,
      default: 20
    }
  },
  data() {
    const selected = this.options.find(item => item.id === this.selected)

    return {
      uid: null,
      loading: false,
      isMenuOpened: false,
      active: this.selected,
      isClearButtonShowing: false,
      menuTop: null,
      menuBottom: null,
      menuLeft: null,
      menuWidth: null,
      slotOptions: null,
      hasNoTextData: false,
      endpointOptions: [],
      cacheEndpointOptions: [],
      cacheDisplayOptions: [],
      endpointDataCache: {},
      cacheLabel: null,
      text: this.multiple ? '' : selected?.title || selected?.name,
      cacheQuery: '',
      totalCount: 0,
      qtyPage: 1
    }
  },
  computed: {
    locale() {
      return this.$store.getters.getLocale
    },
    opt() {
      if (this.serviceHandler) {
        return this.endpointOptions
      } else if (this.$slots.default) {
        return this.slotOptions
      } else {
        return this.options
      }
    },
    isFilterable() {
      return this.filterable || !!this.serviceHandler
    },
    filteredOptions() {
      if (this.serviceHandler) return this.opt

      if (!this.isFilterable || !this.text || this.hasNoTextData)
        return this.opt

      const query = this.text.toLowerCase()

      return this.opt.filter(item => {
        const name = item.title || item.name

        return name.toLowerCase().includes(query)
      })
    },
    isClearButton() {
      if (this.disabled) return false

      return (
        this.clearable &&
        this.hasActive &&
        (this.isClearButtonShowing || this.isMenuOpened)
      )
    },
    showedItems() {
      if (!this.multiple || !this.active) return []

      return this.showAllSelectedItems ? this.active : this.active.slice(0, 2)
    },
    selectedOption() {
      if (this.multiple) {
        return this.opt?.find(item => {
          return item.id === this.selected[0]
        })
      } else {
        return this.opt?.find(item => item.id === this.selected)
      }
    },
    noSelectAll() {
      return !!this.serviceHandler
    },
    hasActive() {
      return (
        (Array.isArray(this.active) && this.active?.length) ||
        typeof this.active === 'number'
      )
    }
  },
  watch: {
    options() {
      if (!this.multiple) {
        const option = this.opt.find(item => item.id === this.selected)
        this.text = this.cacheLabel = option?.title || option?.name
      }
    },
    selected(value) {
      this.active = value

      if (!this.multiple) {
        const option = this.opt?.find(item => item.id === value)
        this.text = this.cacheLabel = option?.title || option?.name
      }
    },
    endpointOptions(options) {
      if (options?.length && !this.multiple) {
        const option = options.find(item => item.id === this.active)

        if (option) {
          this.cacheLabel = option?.title || option?.name
        }
      }
    }
  },
  created() {
    this.uid = (+new Date()).toString(16)

    if (
      !!this.multiple !== !!Array.isArray(this.selected) &&
      this.selected !== null
    ) {
      console.warn('Wrong type of selected prop. Correct type: array')
    }

    this.loadServiceData({ isCache: true })

    if (!this.multiple) {
      this.cacheLabel = this.text
    }
  },
  mounted() {
    if (this.$slots.default) {
      this.hasNoTextData = this.$slots.default.some(child => {
        return (
          child.componentOptions.children.length > 1 ||
          (!child.elm?.innerText &&
            !child.child?.componentOptions?.children?.[0]?.classList?.contains(
              'r-text'
            ))
        )
      })

      this.slotOptions = this.$slots.default.map(child => {
        const title = this.hasNoTextData
          ? null
          : child.componentOptions.children[0].text ||
            child.componentOptions.children[0].componentOptions?.children?.[0]
              .text

        return {
          id: child.componentOptions.propsData.id,
          disabled: child.componentOptions.propsData.disabled,
          title: title ? title.trim() : null,
          html: this.hasNoTextData ? [child] : null
        }
      })

      const value = this.opt.find(item => item.id === this.active)
      this.text = value?.title || value?.name
    }

    this.updateMenuCoords()
    document.addEventListener('scroll', this.closeMenu, true)
    document.addEventListener(
      'mouseup',
      this.closeMenu,
      { passive: true },
      true
    )
    eventBus.on(SELECT_OPEN, this.hideByOtherSelect)
  },
  beforeDestroy() {
    this.closeMenu()

    document.removeEventListener('mouseup', this.closeMenu)
    document.removeEventListener('scroll', this.closeMenu)

    eventBus.off(SELECT_OPEN, this.hideByOtherSelect)
  },
  methods: {
    filterInputHandler: debounce(function (val) {
      this.text = val

      if (!val && !this.hasActive) {
        this.endpointOptions = []
        this.cacheEndpointOptions = []
      }

      if (this.serviceHandler) {
        const query = val?.toLowerCase()?.trim()

        if (!query || query?.length < 2) {
          if (query === '') {
            this.resetSearchFilter()
            this.loadServiceData({ reset: true })
          } else {
            this.endpointOptions = []
          }
        } else {
          this.loadServiceData()
        }
      }

      this.$emit('input', this.active, val)
    }, 256),
    inputClickHandler() {
      if (this.disabled) return

      this.toggleMenu()
    },
    itemClickHandler(id, event, name) {
      if (!id && id !== 0) {
        this.isMenuOpened = false

        return
      }

      const item = this.opt.find(item => item.id === id)

      if (!this.multiple || this.hasNoTextData) {
        if (
          item.id === this.active ||
          (this.hasNoTextData && this.multiple && this.active.includes(item.id))
        ) {
          // no need to change value
          this.isMenuOpened = false

          return
        }

        if (this.serviceHandler && !this.endpointDataCache[id]) {
          if (!this.endpointDataCache[id]) {
            this.endpointDataCache[id] = item
          }

          this.removeEndpointCacheItem(this.active)
        }

        this.active = this.multiple ? [item.id] : item.id
        this.text = this.cacheLabel = item.title || item.name
        this.isMenuOpened = false
      } else {
        this.active = this.active ?? []
        this.$refs.wrapper.focus()

        const index = this.active.indexOf(item.id)

        if (index < 0) {
          if (!this.active) {
            this.active = [item.id]
          } else {
            this.active.push(item.id)
          }

          if (this.serviceHandler && !this.endpointDataCache[id]) {
            this.endpointDataCache[id] = item
          }
        } else {
          this.active = this.active.filter(el => el !== item.id)
          this.removeEndpointCacheItem(id)
        }
      }

      this.$nextTick(() => this.updateMenuCoords())
      this.$emit('change', this.active, name)
    },
    toggleMenu() {
      if (this.disabled) return

      this.isMenuOpened = !this.isMenuOpened

      if (this.isMenuOpened) {
        this.qtyPage = 1
        this.resetSearchFilter()
        this.loadServiceData()
        this.updateMenuCoords()

        if (this.serviceHandler && !this.hasActive) {
          this.endpointOptions = []
        }
      }

      if (!this.multiple && this.isFilterable) {
        this.text = null
      }

      eventBus.emit(SELECT_OPEN, this.uid)
    },
    hideByOtherSelect(id) {
      if (id !== this.uid) {
        this.isMenuOpened = false

        if (this.text && this.multiple) this.text = null

        if (!this.multiple) {
          const value = this.opt?.find(item => item.id === this.active)

          if (this.serviceHandler && !value) {
            const cacheOption = this.endpointDataCache[this.active]
            this.text = cacheOption?.name || this.active
          } else {
            this.text = value?.title || value?.name
          }
        }
      }
    },
    clearButtonClickHandler() {
      if (this.disabled) return

      this.resetSearchFilter()
      this.loadServiceData({ reset: true })

      if (this.isFilterable) {
        if (this.$refs.filter) this.$refs.filter.focus()

        this.isMenuOpened = true
      }

      this.active = !this.multiple ? null : []

      this.$nextTick(() => {
        this.updateMenuCoords()
      })

      this.$emit('change', this.active)

      eventBus.emit(SELECT_OPEN, this.uid)
    },
    closeMenu(e) {
      if (!this.isMenuOpened) return

      if (e === undefined) {
        this.isMenuOpened = false

        return
      }

      /**
       * called on an input that has lost focus, or when scrolling, or click outside
       * for @mouseup and scroll. Checks that click was inside select
       */
      const clickIsInsideSelect =
        !e?.target.closest('.select-menu__menu-item') &&
        !e?.target.closest('.r-select__input-wrapper') &&
        !e?.target.closest('.r-select__dropdown-icon') &&
        !e?.target.closest('.select-menu')

      if (clickIsInsideSelect) {
        if (!this.multiple) {
          const value = this.opt?.find(item => item.id === this.active)

          if (this.serviceHandler && !value) {
            const cacheOption = this.endpointDataCache[this.active]
            this.text = cacheOption?.name || this.active
          } else {
            this.text = value?.title || value?.name
          }
        } else {
          this.text = null
        }

        this.isMenuOpened = false
      }
    },
    updateMenuCoords() {
      if (!this.$refs.wrapper) return

      this.menuTop = null
      this.menuBottom = null

      const wrapperNodeRect = this.$refs.wrapper.getBoundingClientRect()
      const windowHeight = document.documentElement.clientHeight
      const menuOnTop = wrapperNodeRect.bottom > windowHeight - 250
      this.minWidth = wrapperNodeRect.width

      if (menuOnTop) {
        this.menuBottom = windowHeight - wrapperNodeRect.top + 8
        this.menuTop = null
      } else {
        this.menuTop = wrapperNodeRect.bottom + (this.dropdownStyle ? 8 : 4)
        this.menuBottom = null
      }

      this.menuLeft = wrapperNodeRect.left
      this.menuWidth = wrapperNodeRect.width
    },
    getOptionTitleById(id) {
      const option = this.opt?.find(item => item.id === id)

      if (this.serviceHandler && !option) {
        const cacheOption = this.endpointDataCache[id]

        return cacheOption?.name || id
      }

      return !option ? id : this.getOptionTitle(option)
    },
    getOptionTitle(option) {
      const title = option.title || option.name
      const isLocaleObject =
        title instanceof Object &&
        !(title instanceof Array) &&
        (!!title[this.locale] || title[this.locale] === '')

      return isLocaleObject ? title[this.locale] : title
    },
    deleteSelectedItem(id) {
      if (this.disabled || this.opt.find(item => item.id === id)?.disabled)
        return

      this.active = this.active.filter(item => item !== id)

      this.removeEndpointCacheItem(id)
      this.$emit('change', this.active)

      if (this.isMenuOpened) {
        this.$nextTick(() => this.updateMenuCoords())
      }
    },
    removeEndpointCacheItem(id) {
      if (this.endpointDataCache[id]) {
        delete this.endpointDataCache[id]
      }
    },
    selectAll() {
      const allEnabledOptionsSelected = this.opt
        .filter(option => !option.disabled)
        .map(item => item.id)
        .every(option => this.active.includes(option))

      const disabledSelectedOptionsIds = this.opt
        .filter(option => option.disabled)
        .map(disabledOption => disabledOption.id)
        .filter(id => this.active.includes(id))

      if (allEnabledOptionsSelected) {
        this.active = disabledSelectedOptionsIds
      } else {
        this.opt
          .filter(item => !item.disabled)
          .forEach(option => {
            if (this.active.indexOf(option.id) === -1)
              this.active.push(option.id)
          })
      }

      this.$emit('change', this.active)
      this.$nextTick(() => this.updateMenuCoords())
    },
    async loadServiceData(options = {}) {
      if ((options?.isCache && !this.hasActive) || !this.serviceHandler) return

      try {
        this.loading = true

        const isObject = obj =>
          Object.prototype.toString.call(obj) === '[object Object]'

        const params = {
          step: this.step,
          page: options?.page || 1
        }

        if (this.text && !options?.page) {
          params.query = this.text?.toLowerCase()?.trim()
          params.page = 1
        }

        if (options?.isCache) {
          params.selected = this.active
        } else {
          this.cacheQuery = this.text?.trim()
        }

        if (options.reset) {
          delete params.query
          delete params.selected
        }

        const { data: response } = await this.serviceHandler(params)

        if (typeof response === 'string') return

        this.totalCount = response?.total || 0

        if (response) {
          if (isObject(response)) {
            if (response?.data) {
              if (params.page > 1) {
                this.endpointOptions.push(...response.data)
              } else {
                this.endpointOptions = response.data
              }
            } else {
              this.endpointOptions = []
            }
          } else {
            this.endpointOptions = response
          }
        } else {
          this.endpointOptions = []
        }

        if (this.multiple) {
          this.cacheDisplayOptions.push(...this.endpointOptions)
        }

        if (options?.isCache) {
          this.cacheEndpointOptions = cloneDeep(this.endpointOptions)

          this.endpointOptions.forEach(item => {
            this.endpointDataCache[item.id] = item
          })
        }
      } catch (e) {
        throw new Error(e)
      } finally {
        this.loading = false
      }
    },
    async showMoreHandler() {
      this.qtyPage++

      await this.loadServiceData({
        page: this.qtyPage
      })
    },
    resetSearchFilter() {
      this.text = ''
      this.cacheQuery = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.r-select {
  $self: &;

  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 0.25rem;

  /**
   * Style
   */

  &.dropdown-style {
    .r-select__input-wrapper {
      background: $accent-primary-1;
      border: none;
    }

    .r-select__input {
      font-weight: 600;
      color: $button-primary-bg !important;
    }
  }

  &__text-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  /**
   * State
   */

  &.show-all {
    .r-select__selected-item {
      max-width: 128px;
    }

    .r-select__selected-items {
      flex-wrap: wrap;
    }
  }

  &.disabled {
    .r-select__input {
      cursor: not-allowed;
    }

    .r-select__dropdown-icon,
    .r-select__selected-item,
    .r-select__input-wrapper {
      opacity: 0.4;
    }

    .r-select__input-wrapper {
      cursor: not-allowed;
    }
  }

  &.opened {
    .r-select__dropdown-icon {
      transform: scale(1, -1);
    }

    .r-select__input-wrapper {
      border-color: $field-active-border;
    }
  }

  &.error {
    .r-select__input-wrapper {
      border-color: $accent-danger;
    }
  }

  /**
   * Elements
   */
  &__input-wrapper {
    flex: 1;
    display: flex;
    gap: 4px;
    position: relative;
    background: $field-bg;
    cursor: pointer;
    align-items: center;
    padding: 0.25rem 2.5rem 0.25rem 0.5rem;
    border-radius: $border-radius;
    border: 1px solid $field-border;
    min-height: 36px;
    transition: border 0.25s ease;

    &.angular {
      border-radius: 0;
    }
  }

  &__selected-items {
    display: flex;
    gap: 4px;
    align-items: center;
    width: 100%;
    overflow: hidden;

    &--no-crop {
      flex-wrap: wrap;

      #{$self}__selected-item {
        max-width: 100% !important;
      }
    }
  }

  &__selected-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.25rem;
    flex-shrink: 0;
    border: 1px solid $dividers-high-contrast;
    border-radius: $border-radius;
    padding: 0.25rem;
    max-width: 128px;
    line-height: 1rem;

    &-more {
      line-height: 1rem;
    }

    &-text {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__selected-delete {
    flex-shrink: 0;
    line-height: 1;
    height: auto;
  }

  &__clear-button {
    position: absolute;
    right: 0.25rem;
    top: calc(50% - 14px);
  }

  &__dropdown-icon {
    position: absolute;
    right: 0.25rem;
    top: calc(50% - 14px);
    transform: scale(1);
  }

  &__no-text-data-wrapper {
    display: flex;
    align-items: center;
  }
}

/**
 * Animation
 */
.unroll-enter-active,
.unroll-leave-active {
  transition: all 0.1s ease;
  overflow: hidden;
  display: block;
  max-height: 250px;
  opacity: 1;
}

.unroll-enter,
.unroll-leave-to {
  max-height: 0;
  opacity: 0.5;
}
</style>
