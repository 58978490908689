<template>
  <div class="r-slider">
    <r-text
      v-if="label"
      color-type="subhead"
    >
      {{ label }}
    </r-text>
    <div
      class="r-slider__wrapper"
      :class="{ 'no-input': noInput }"
    >
      <input
        v-model="input"
        type="range"
        :style="{ backgroundSize: interval }"
        :min="min"
        :max="max"
        :disabled="disabled"
        :step="rangeStep"
        @input="updateInput"
      />
      <r-input-number
        v-if="!noInput"
        v-model="input"
        :min="min"
        :max="max"
        :disabled="disabled"
        mini
        :step="step"
        controls="right"
        class="r-slider__input"
        @input="updateInput"
      />
    </div>
  </div>
</template>

<script>
import { debounce } from 'HELPERS'
import { ref, computed } from 'vue'

export default {
  props: {
    label: {
      type: String,
      default: null
    },
    value: {
      type: Number,
      default: 0
    },
    noInput: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 9
    },
    step: {
      type: Number,
      default: null
    }
  },
  setup(props, { emit }) {
    const input = ref(props.value)
    const interval = computed(() => {
      return (
        ((input.value - props.min) * 100) / (props.max - props.min) + '% 100%'
      )
    })

    const rangeStep = computed(() => {
      const arr = props.max.toString().split('.')

      if (arr?.length <= 1) return props.step

      const stepArr = (props.step || 1).toString().split('.')
      const stepCount = stepArr[1]?.length || 0
      const count = arr[1]?.length || 0

      if (stepCount >= count) return props.step

      return 1 / 10 ** count
    })

    const updateInput = function (e) {
      let val = e

      if (typeof e === 'object') {
        let target = e.target

        if (e.target.type !== 'range') {
          target = document.getElementById('range')
        }

        val = +target.value
      }

      return debounce(emit('change', +val), 250)
    }

    return {
      rangeStep,
      input,
      interval,

      updateInput
    }
  },
  watch: {
    // i added it for updating slider value on active route option change (dt pt)
    value(val) {
      this.input = val
    }
  }
}
</script>

<style lang="scss" scoped>
.r-slider {
  display: grid;
  grid-gap: 0.5rem;
  width: 100%;

  &__wrapper {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr auto;
    align-items: center;

    &.no-input {
      grid-template-columns: auto;
    }
  }

  &__input {
    width: 100px;
  }

  @mixin thumb {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background: $accent-primary;
  }

  input[type='range']:not([disabled]):active::-webkit-slider-thumb {
    cursor: grabbing;
  }

  input[type='range']:not([disabled]):active::-moz-range-thumb {
    cursor: grabbing;
  }

  input[type='range'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    height: 2px;
    background-color: $dividers-low-contrast;
    border-radius: 0.25rem;
    background-image: linear-gradient($accent-primary, $accent-primary);
    background-repeat: no-repeat;
    cursor: pointer;
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    @include thumb;
    cursor: grab;
  }

  input[type='range']::-moz-range-thumb {
    -moz-appearance: none;
    border: none;
    @include thumb;
    cursor: grab;
  }

  input[disabled],
  input[disabled]::-webkit-slider-thumb {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
</style>
