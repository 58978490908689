import { render, staticRenderFns } from "./r-select.vue?vue&type=template&id=2efaa408&scoped=true"
import script from "./r-select.vue?vue&type=script&lang=js"
export * from "./r-select.vue?vue&type=script&lang=js"
import style0 from "./r-select.vue?vue&type=style&index=0&id=2efaa408&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2efaa408",
  null
  
)

export default component.exports