import vue from 'vue'
import { structure, nonClearableFields } from './structure'
import { cloneDeep } from 'HELPERS'

export default {
  state: {
    ...cloneDeep(structure)
  },
  getters: {
    getKsoddField: state => field => state[field],
    getKsoddActiveLayers: state => state.activeLayers,
    getKsoddFiltersById: state => id => state.layerStyles[id]?.filters || [],
    getKsoddLayerStyles: state => state.layerStyles,
    getKsoddSelectedCity: state => state.city,
    getKsoddHashById: state => id => state.hash[id]
  },
  mutations: {
    KSODD_STORE_RESET(state) {
      for (const field in structure) {
        if (nonClearableFields.includes(field)) continue
        state[field] = cloneDeep(structure[field])
      }
    },
    KSODD_SET(state, [field, value]) {
      if (field.includes('.')) {
        const [parent, child] = field.split('.')
        vue.set(state[parent], child, value)
      } else {
        state[field] = value
      }
    },
    KSODD_SET_FILTER_PROP(state, { filterId, action, layerId, prop, value }) {
      const { filters } = state.layerStyles[layerId]
      const index = filters.findIndex(({ id }) => id === filterId)

      if (action === 'delete') {
        filters.splice(index, 1)
      } else if (!filters[index]) {
        console.warn('filter not found!')

        return
      } else if (prop && value !== undefined) {
        filters[index][prop] = value
      }
    },
    KSODD_PUSH(state, [field, value]) {
      state[field].push(value)
    },
    KSODD_SPLICE(state, [field, value]) {
      const index = state[field].indexOf(value)

      if (index >= 0) state[field].splice(index, 1)
    },
    KSODD_ASSIGN(state, { field, key, value }) {
      state[field] = Object.assign({ ...state[field] }, { [key]: value })
    },
    KSODD_MERGE(state, { field, object }) {
      state[field] = Object.assign({ ...state[field] }, object)
    }
  }
}
