export const structure = {
  city: null,
  tree: {},
  map: {},
  layerStyles: {},
  activeLayers: [],
  fields: {},
  isSelectDisabled: false,
  hash: {},
  layerNames: {},
  legendWidgetsOnMap: {}
}

export const nonClearableFields = [
  'city',
  'map',
  'layerStyles',
  'activeLayers',
  'legendWidgetsOnMap'
]
