import {
  GET_DT_ROOT_ENDPOINT,
  GET_TILESERVER_ROOT_ENDPOINT
} from '@/api/modeling'
import { GET_MODELING_ROOT_ENDPOINT } from '@/api/modeling'

export default (beforeEnter, wrapper) => {
  return {
    name: 'forecast',
    path: 'forecast',
    beforeEnter,
    component: wrapper,
    meta: {
      title: 'forecast:title',
      icon: 'tune-component',
      description: 'forecast:desc'
    },
    children: [
      {
        name: 'digital-twin',
        path: 'digital-twin',
        beforeEnter,
        component: () => import('@/modules/sub-modules/modeling-wrapper'),
        redirect: 'digital-twin/dt-selector',
        meta: {
          title: 'digital-twin:title',
          abbr: 'digital-twin:abbr-title',
          icon: 'edit-geometry',
          description: 'digital-twin:desc',
          endpoints: [GET_DT_ROOT_ENDPOINT(), GET_TILESERVER_ROOT_ENDPOINT()],
          projects: [
            'gbu_bdd_kazan',
            'rostov_on_don',
            'vladikavkaz',
            'cheboksary',
            'academic',
            'loadtest',
            'spb_gasu',
            'labgrad',
            'cpt_spb',
            'irano',
            'urgups',
            'niipi',
            'eputs',
            'tomsk',
            'madi',
            'omsk',
            'tiu'
          ]
        },
        children: [
          {
            name: 'dt-selector',
            path: 'dt-selector',
            beforeEnter,
            component: () => import('@/modules/digital-twin/views/dt-selector'),
            meta: {
              title: 'digital-twin:title',
              subtitle: 'digital-twin:select-model',
              abbr: 'digital-twin:abbr-title',
              icon: 'edit-geometry',
              description: 'digital-twin:desc',
              isModelSelector: true
            }
          },
          {
            name: 'dt-main',
            path: 'dt-main',
            beforeEnter,
            component: () => import('@/modules/digital-twin/views/dt-main'),
            meta: {
              title: 'digital-twin:title',
              icon: 'edit-geometry',
              description: 'digital-twin:desc',
              isMap: true
            }
          },
          {
            name: 'pt-editor',
            path: 'pt-editor',
            beforeEnter,
            component: () => import('@/modules/digital-twin/views/pt-editor'),
            meta: {
              subtitle: 'digital-twin:pt-subtitle',
              title: 'digital-twin:title',
              icon: 'edit-geometry',
              description: 'digital-twin:desc',
              isMap: true
            }
          },
          {
            name: 'dt-book',
            path: 'book',
            beforeEnter,
            component: () => import('@/modules/digital-twin/views/dt-book'),
            meta: {
              title: 'digital-twin:title',
              subtitle: 'digital-twin:model-book',
              abbr: 'digital-twin:abbr-title',
              icon: 'edit-geometry',
              description: 'digital-twin:desc'
            }
          },
          {
            name: 'schedule-editor',
            path: 'schedule-editor',
            beforeEnter,
            component: () =>
              import('@/modules/digital-twin/views/schedule-editor'),
            meta: {
              title: 'digital-twin:title',
              subtitle: 'dt:schedule-editor',
              icon: 'edit-geometry',
              description: 'digital-twin:desc'
            }
          }
        ]
      },
      {
        name: 'modeling',
        path: 'modeling',
        beforeEnter,
        component: () => import('@/modules/sub-modules/modeling-wrapper'),
        redirect: 'modeling/selector',
        meta: {
          title: 'modeling:title',
          abbr: 'modeling:abbr-title',
          icon: 'calculator',
          description: 'modeling:desc',
          endpoints: [
            GET_MODELING_ROOT_ENDPOINT(),
            GET_TILESERVER_ROOT_ENDPOINT()
          ],
          projects: [
            'gbu_bdd_kazan',
            'rostov_on_don',
            'vladikavkaz',
            'cheboksary',
            'vladimir',
            'academic',
            'tashkent',
            'loadtest',
            'spb_gasu',
            'labgrad',
            'cpt_spb',
            'irano',
            'urgups',
            'tomsk',
            'niipi',
            'eputs',
            'madi',
            'omsk',
            'tiu'
          ]
        },
        children: [
          {
            name: 'selector',
            path: 'selector',
            beforeEnter,
            component: () =>
              import('@/modules/sub-modules/model-selector/model-selector'),
            meta: {
              title: 'modeling:title',
              subtitle: 'modeling:selector-subtitle',
              abbr: 'modeling:abbr-title',
              icon: 'calculator',
              description: 'modeling:desc',
              isModelSelector: true
            }
          },
          {
            name: 'model-view',
            path: 'model-view',
            beforeEnter,
            component: () =>
              import('@/modules/modeling/views/model-view/model-view'),
            meta: {
              title: 'modeling:title',
              subtitle: 'modeling:view-subtitle',
              abbr: 'modeling:abbr-title',
              icon: 'calculator',
              description: 'modeling:desc',
              isMap: true
            }
          },
          {
            name: 'modeling-scenario',
            path: 'scenario/:id',
            beforeEnter,
            component: () =>
              import('@/modules/modeling/views/model-view/calculated-scenario'),
            meta: {
              title: 'modeling:title',
              subtitle: 'modeling:modeling-scenario-view',
              abbr: 'modeling:abbr-title',
              icon: 'calculator',
              isMap: true
            }
          },
          {
            name: 'scenario-comparison',
            path: 'scenario-comparison/:id',
            beforeEnter,
            component: () =>
              import('@/modules/modeling/views/model-view/scenario-comparison'),
            meta: {
              title: 'modeling:title',
              subtitle: 'modeling:modeling-scenario-comparison',
              abbr: 'modeling:abbr-title',
              icon: 'calculator',
              isMap: true
            }
          },
          {
            name: 'matrices',
            path: 'matrices',
            beforeEnter,
            component: () =>
              import('@/modules/modeling/views/matrices/matrices'),
            meta: {
              title: 'modeling:title',
              subtitle: 'modeling:matrices-subtitle',
              abbr: 'modeling:abbr-title',
              icon: 'calculator',
              description: 'modeling:desc'
            }
          },
          {
            name: 'procedures',
            path: 'procedures',
            beforeEnter,
            component: () =>
              import('@/modules/modeling/views/procedures/procedures'),
            meta: {
              title: 'modeling:title',
              subtitle: 'modeling:procedures-subtitle',
              abbr: 'modeling:abbr-title',
              icon: 'calculator',
              description: 'modeling:desc'
            }
          },
          {
            name: 'book',
            path: 'book',
            beforeEnter,
            component: () => import('@/modules/modeling/views/modeling-book'),
            meta: {
              title: 'modeling:title',
              subtitle: 'modeling:book-subtitle',
              abbr: 'modeling:abbr-title',
              icon: 'calculator',
              description: 'modeling:desc'
            }
          },
          {
            name: 'optimization-calc',
            path: 'optimization-calc',
            beforeEnter,
            component: () =>
              import(
                '@/modules/modeling/views/optimization-calc/optimization-calc'
              ),
            meta: {
              title: 'modeling:title',
              subtitle: 'modeling:oc-subtitle',
              abbr: 'modeling:abbr-title',
              icon: 'calculator',
              description: 'modeling:desc',
              isMap: true
            }
          },
          {
            name: 'isochrones',
            path: 'isochrones',
            beforeEnter,
            component: () =>
              import('@/modules/modeling/views/isochrones/views'),
            meta: {
              subtitle: 'isochrones',
              icon: 'node-layer',
              endpoints: ['modeling'],
              isMap: true
            }
          },
          {
            name: 'route-search',
            path: 'route-search',
            beforeEnter,
            component: () =>
              import('@/modules/modeling/views/route-search/views'),
            meta: {
              subtitle: 'route-search',
              icon: 'route',
              endpoints: ['modeling'],
              isMap: true
            }
          }
        ]
      }
    ]
  }
}
