export default (beforeEnter, wrapper) => {
  return {
    name: 'management',
    path: 'management',
    beforeEnter,
    component: wrapper,
    meta: {
      title: 'management:title',
      icon: 'calculator',
      description: 'management:desc'
    },
    children: [
      {
        name: 'road_works',
        path: 'road_works',
        beforeEnter,
        component: wrapper,
        redirect: 'road_works/orders',
        meta: {
          title: 'rw:title',
          abbr: 'rw:abbr-title',
          description: 'rw:description',
          endpoints: ['road_works'],
          icon: 'work',
          projects: ['surgut', 'eputs']
        },
        children: [
          {
            name: 'rw-orders',
            path: 'orders',
            component: () => import('@/modules/road-works/views/rw-main.vue'),
            meta: {
              icon: 'work',
              title: 'rw:title',
              abbr: 'rw:abbr-title',
              description: 'rw:description',
              isMap: true
            }
          },
          {
            name: 'rw-book',
            path: 'book',
            component: () => import('@/modules/road-works/views/rw-book.vue'),
            meta: {
              title: 'rw:title',
              abbr: 'rw:abbr-title',
              description: 'rw:description'
            }
          },
          {
            name: 'rw-routes',
            path: 'routes',
            component: () => import('@/modules/road-works/views/rw-routes.vue'),
            meta: {
              title: 'rw:title',
              abbr: 'rw:abbr-title',
              description: 'rw:description',
              isMap: true
            }
          },
          {
            name: 'rw-quarterly',
            path: 'quarterly',
            component: () =>
              import('@/modules/road-works/views/rw-quarterly.vue'),
            meta: {
              icon: 'work',
              title: 'rw:title',
              subtitle: 'rw:quarterly-title',
              abbr: 'rw:abbr-title',
              isMap: true
            }
          },
          {
            name: 'rw-geofence-editor',
            path: 'geofence-editor',
            component: () =>
              import('@/modules/road-works/views/rw-geofence-editor.vue'),
            meta: {
              icon: 'work',
              title: 'rw:title',
              subtitle: 'rw:geofence-editor-title',
              abbr: 'rw:abbr-title',
              description: 'rw:description',
              isMap: true
            }
          },
          {
            name: 'rw-quarterly-registry',
            path: 'quarterly-registry',
            component: () =>
              import('@/modules/road-works/views/rw-quarterly-registry.vue'),
            meta: {
              icon: 'ksodd-tables',
              title: 'rw:title',
              subtitle: 'rw:quarterly-registry-title',
              abbr: 'rw:abbr-title',
              description: 'rw:description'
            }
          }
        ]
      },
      {
        name: 'asudd',
        path: 'asudd',
        beforeEnter,
        component: wrapper,
        redirect: 'asudd/main',
        meta: {
          title: 'asudd:title',
          abbr: 'asudd:abbr-title',
          icon: 'traffic-light',
          description: 'asudd:desc',
          endpoints: ['asudd'],
          projects: ['eputs', 'ryazan', 'vladimir', 'tashkent']
        },
        children: [
          {
            name: 'asudd-main',
            path: 'main',
            beforeEnter,
            component: () => import('@/modules/asudd/views/asudd'),
            meta: {
              title: 'asudd:title',
              abbr: 'asudd:abbr-title',
              icon: 'traffic-light',
              description: 'asudd:desc',
              isMap: true
            }
          },
          {
            name: 'asudd-greenwave',
            path: 'greenwave',
            beforeEnter,
            component: () => import('@/modules/asudd/views/greenwave'),
            meta: {
              title: 'asudd:title',
              subtitle: 'asudd:greenwave',
              abbr: 'asudd:abbr-title',
              icon: 'traffic-light',
              description: 'asudd:desc',
              isMap: true
            }
          },
          {
            name: 'asudd-greenwave-chart',
            path: 'greenwave-chart',
            meta: {
              title: 'asudd:title',
              subtitle: 'asudd:greenwave',
              abbr: 'asudd:abbr-title',
              icon: 'traffic-light',
              description: 'asudd:desc'
            },
            beforeEnter,
            component: () => import('@/modules/asudd/views/greenwave-chart')
          }
        ]
      },
      {
        name: 'scenario',
        path: 'scenario',
        beforeEnter,
        component: wrapper,
        redirect: 'scenario/main',
        meta: {
          title: 'scenario:title',
          abbr: 'scenario:abbr-title',
          icon: 'scenario-plan',
          description: 'scenario:desc',
          endpoints: ['scenario_plans'],
          projects: ['eputs']
        },
        children: [
          {
            name: 'scenario-main',
            path: 'main',
            beforeEnter,
            component: () => import('@/modules/scenario/views/scenario'),
            meta: {
              title: 'scenario:title',
              icon: 'scenario-plan',
              abbr: 'scenario:abbr-title',
              description: 'scenario:desc'
            }
          }
        ]
      },
      {
        name: 'emergency',
        path: 'emergency',
        beforeEnter,
        component: wrapper,
        redirect: 'emergency/main',
        meta: {
          icon: 'tune',
          endpoints: ['dispatch_control'],
          projects: ['eputs', 'surgut', 'tashkent'],
          title: 'emergency:title',
          abbr: 'emergency:abbr-title',
          description: 'emergency:desc'
        },
        children: [
          {
            name: 'emergency-main',
            path: 'main',
            beforeEnter,
            component: () => import('@/modules/emergency/views/emergency'),
            meta: {
              title: 'emergency:title',
              abbr: 'emergency:abbr-title',
              icon: 'tune',
              description: 'emergency:desc',
              isMap: true
            }
          },
          {
            name: 'emergency-registry',
            path: 'registry',
            beforeEnter,
            component: () => import('@/modules/emergency/views/registry'),
            meta: {
              title: 'emergency:registry_title',
              description: 'emergency:registry_desc'
            }
          }
        ]
      },
      {
        name: 'info',
        path: 'info',
        beforeEnter,
        component: wrapper,
        redirect: 'info/main',
        meta: {
          title: 'info:title',
          abbr: 'info:abbr_title',
          icon: 'info-screen-1',
          description: 'info:desc',
          endpoints: ['mciud'],
          projects: ['eputs']
        },
        children: [
          {
            name: 'info-main',
            path: 'main',
            beforeEnter,
            component: () => import('@/modules/info/views/info'),
            meta: {
              title: 'info:title',
              abbr: 'info:abbr_title',
              icon: 'info-screen-1',
              description: 'info:desc',
              isMap: true
            }
          },
          {
            name: 'info-registry',
            path: 'registry',
            beforeEnter,
            component: () => import('@/modules/info/views/registry'),
            meta: {
              abbr: 'info:abbr_title',
              icon: 'info-screen-1',
              subtitle: 'info:registry_title',
              description: 'info:registry_description'
            }
          },
          {
            name: 'info-group-mode',
            path: 'group-mode/:data_type',
            beforeEnter,
            component: () => import('@/modules/info/views/info-group-mode'),
            meta: {
              abbr: 'info:abbr_title',
              icon: 'folder',
              subtitle: 'info:group_mode_title',
              description: 'info:group_mode_description'
            }
          }
        ]
      }
    ]
  }
}
