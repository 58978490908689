export const structure = {
  map: {},
  city: null,
  fields: {},
  statuses: [],
  layersConfig: null,
  objectCard: null,
  objectEditor: null,
  activeLayers: [],
  hasUnsavedData: false,
  layerObjectList: null
}

export const nonClearableFields = ['map']
