export const structure = {
  tree: [],
  map: {},
  layerStyles: {},
  activeLayers: [],
  fields: {},
  isSelectDisabled: false,
  zIndex: {},
  layerNames: {},
  objectsCount: {},
  legendWidgetsOnMap: {
    // example:
    // 469: {
    //   enabled: true
    // top: 54,
    // left: 100
    // }
  }
}

export const nonClearableFields = [
  'layerStyles',
  'activeLayers',
  'zIndex',
  'map',
  'legendWidgetsOnMap'
]
