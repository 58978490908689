import { getQueryParameters } from 'HELPERS'
import { ABS_TILESERVER_URL } from '@/config/constants'

export const GET_DT_ROOT_ENDPOINT = ({ onlyDt } = {}) => {
  const query = getQueryParameters()

  return query.modificationId && !onlyDt ? `modeling` : `digital_twin`
}

export const GET_MODELING_ROOT_ENDPOINT = () => {
  return `modeling`
}

export const GET_DT_TREE_URL = ({ modelId, modificationId }) => {
  const modificationUrlPart = modificationId
    ? `/modifications/${modificationId}`
    : ''

  return `${GET_DT_ROOT_ENDPOINT()}/models/${modelId}${modificationUrlPart}${
    modificationId ? '/model' : ''
  }?gis=true`
}

export const GET_DT_ATTRIBUTE_GROUPS_URL = () => {
  return `${GET_DT_ROOT_ENDPOINT()}/attribute_groups`
}

export const GET_MODELING_ATTRIBUTE_GROUPS_URL = () => {
  return `${GET_MODELING_ROOT_ENDPOINT()}/attribute_groups`
}

export const GET_TILESERVER_ROOT_ENDPOINT = () => {
  return `tileserver`
}

export const GET_TR_SIT_QUALITY_SCORE_FULL_URL = ({ byDay } = {}) => {
  return `${GET_TR_SIT_ROOT_ENDPOINT()}/quality/score${
    byDay ? '/' + byDay : ''
  }`
}

export const GET_PROCEDURES_IMPORT_FULL_URL = ({ modelId }) => {
  const proceduresUrlPart = 'procedure_sets'

  // eslint-disable-next-line vue/max-len
  return `${GET_MODELING_ROOT_ENDPOINT()}/models/${modelId}/${proceduresUrlPart}/import`
}

export const GET_PROCEDURE_GROUP_FULL_URL = ({ setId, id, modelId }) => {
  const groupURLPartConfig = 'groups'

  // eslint-disable-next-line vue/max-len
  return `${GET_MODELING_ROOT_ENDPOINT()}/models/${modelId}/procedure_sets/${setId}/${groupURLPartConfig}/${id}/procedures`
}

export const GET_TILESERVER_FULL_URL = ({
  modelId,
  layerId,
  tableName,
  prevTable,
  hidePrevTable
}) => {
  // используется tableName или modelId_layerId
  const query = getQueryParameters()
  const modificationId = query.modificationId

  const preparedLayerId = layerId?.includes('traffic.')
    ? layerId.replace('traffic.', '')
    : layerId

  const isTrafficLinks = preparedLayerId?.includes('traffic_links')

  const customTableName = modelId ? `${modelId}_${layerId}` : preparedLayerId
  // eslint-disable-next-line vue/max-len
  const base = `${ABS_TILESERVER_URL}/${GET_TILESERVER_ROOT_ENDPOINT()}/tiles/WebMercatorQuad`

  const newVersionUrl = `/${isTrafficLinks ? 'traffic' : 'modeling'}.${
    tableName || customTableName
  }${modificationId ? '_' + modificationId : ''}/{z}/{x}/{y}`

  return base + newVersionUrl
}

export const GET_TR_SIT_ROOT_ENDPOINT = () => {
  return `transport_situation`
}
