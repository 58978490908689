import $store from 'STORE'
import { systemSettings, systemSettingsDefault } from '@/config/system-settings'

export const toggleBodyTheme = newTheme => {
  // const theme = localStorage.getItem('theme')
  let systemConfig
  systemConfig = JSON.parse(localStorage.getItem('systemConfig'))

  if (!systemConfig) {
    systemConfig = {}
    for (let key in systemSettings) {
      systemConfig[systemSettings[key]] =
        systemSettingsDefault[systemSettings[key]]
    }
  }

  // TODO: all fn rewrite

  const theme = systemConfig?.theme

  if (newTheme) {
    // localStorage.setItem('theme', newTheme)
    systemConfig.theme = newTheme
    const config = JSON.stringify(systemConfig)
    localStorage.setItem('systemConfig', config)
  }

  document.body.classList.remove(getTheme(theme))
  document.body.classList.add(getTheme(newTheme || theme))
}

export const getTheme = value => {
  const theme = value || $store.getters.getTheme || 'system'

  if (theme === 'system') {
    return window.matchMedia('(prefers-color-scheme: dark)').matches
      ? 'dark'
      : 'light'
  }

  return theme
}
