<template>
  <div
    class="r-input"
    :class="{ material }"
  >
    <r-text
      v-if="label"
      class="r-input__label"
      color-type="subhead"
    >
      {{ label }}
    </r-text>
    <div class="r-input__body">
      <r-icon
        v-if="iconName"
        class="r-input__left"
        :loading="loading"
        :name="iconName"
        :color-type="iconColor"
      />
      <input
        ref="input"
        class="r-input__input"
        :class="inputClass"
        :placeholder="placeholder"
        :value="value"
        :type="currentType"
        :autocomplete="autocomplete"
        :disabled="disabled"
        :min="min"
        :max="max"
        @input="input"
        @keyup.enter="enter"
        @keypress.enter="onEnterPress"
        @focus="$emit('focus')"
        @blur="$emit('blur')"
      />
      <r-button
        v-if="clearable && value && type !== 'password'"
        class="r-input__clear"
        icon="clear-input"
        simple
        mini
        tabindex="-1"
        @click.prevent.stop="clear"
      />
      <r-button
        v-if="type === 'password'"
        class="r-input__password"
        :icon="getIconSymbol"
        simple
        mini
        tabindex="-1"
        @click.prevent.stop="togglePassword"
      />

      <r-text
        v-if="measure"
        color-type="secondary"
        class="r-input__measure"
      >
        {{ measure }}
      </r-text>
    </div>
  </div>
</template>

<script>
// TODO: blur & focus events
export default {
  props: {
    onEnterPress: {
      type: Function,
      default: () => {}
    },
    value: {
      type: [String, Number],
      default: ' ' // TODO: space instead empty string
    },
    placeholder: {
      type: [String, Number],
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    validator: {
      type: Function,
      default: () => true
    },
    error: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'text'
    },
    autocomplete: {
      type: String,
      default: 'off'
    },
    clearable: {
      type: Boolean,
      default: false
    },
    iconName: {
      type: String,
      default: ''
    },
    iconColor: {
      type: String,
      default: ''
    },
    material: {
      type: Boolean,
      default: false
    },
    mini: {
      type: Boolean,
      default: false
    },
    angular: {
      type: Boolean,
      default: false
    },
    measure: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    max: {
      type: Number,
      default: undefined
    },
    min: {
      type: Number,
      default: undefined
    }
  },
  data() {
    return {
      currentType: this.type,
      validError: false
    }
  },
  computed: {
    inputClass() {
      const {
        material,
        error,
        disabled,
        iconName,
        clearable,
        validError,
        measure,
        angular,
        mini
      } = this

      return {
        material,
        disabled,
        error: error || validError,
        clearable,
        angular,
        measure,
        mini,
        'prefix-icon': iconName
      }
    },
    getIconSymbol() {
      return this.currentType === 'password' ? 'visible-on' : 'visible-off'
    }
  },
  methods: {
    enter($event) {
      this.$emit('enter', $event)
    },
    input($event) {
      const value = $event.target.value

      this.$emit(
        'input',
        this.type === 'number' ? Number(value) : String(value)
      )
      this.validError = !this.validator(value)
    },
    clear() {
      this.$emit('input', '')
      this.$emit('clear-all')
    },
    setFocus() {
      this.$refs.input.focus()
    },
    getStyle() {
      const styles = {}
      styles.resize = this.resize

      return styles
    },
    togglePassword() {
      this.currentType = this.currentType === 'password' ? 'text' : 'password'
    }
  }
}
</script>

<style lang="scss" scoped>
.r-input {
  display: grid;
  grid-gap: 0.25rem;

  &.material {
    grid-gap: 0;
  }

  &__mini {
    padding: 0 0.25rem !important;
    text-align: center;
  }

  &__measure {
    min-height: 100%;
    display: flex;
    align-items: center;
    position: absolute;
    right: 12px;
  }

  &__length {
    min-height: 100%;
    display: flex;
    align-items: flex-end;
    position: absolute;
    right: 12px;
    bottom: 8px;
  }

  &__clear {
    position: absolute;
    right: 4px;
    background: transparent;
    border: none;
    z-index: 1;
    // opacity: 0;
    height: 22px;
  }

  &__password {
    position: absolute;
    right: 4px;
    background: transparent;
    border: none;
    z-index: 1;
    height: 22px;
    cursor: pointer;
  }

  &__left {
    position: absolute;
    left: 8px;
  }

  &__body {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
  }

  &__input {
    height: 36px;
    border-radius: $border-radius;
    padding: 0 12px;
    transition: 0.16s;
    width: 100%;
    color: $text-primary;
    background: $field-bg;
    border: 1px solid $field-border;

    &::placeholder {
      color: $field-placeholder;
    }

    &:focus {
      @include border($field-active-border);
    }

    &.mini {
      height: 28px;
    }

    &.angular {
      border-radius: 0;
    }

    // &:focus ~ .r-input__clear {
    //   opacity: 1;
    //   cursor: pointer;
    // }

    &:focus ~ .r-input__measure {
      display: none;
    }

    &.material {
      border: none;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      @include border-bottom;
      background: transparent;
      border-radius: 0;

      &:focus,
      :hover {
        border: none;
        border-bottom: 1px solid $field-active-border;
      }

      &.error {
        border-color: $accent-danger;
      }
    }

    &.disabled {
      cursor: not-allowed;
      opacity: 0.4;
    }

    &.prefix-icon {
      padding-left: 36px;
    }

    &.iconic-right {
      padding-right: 32px;
    }

    &.measure {
      padding-right: 44px;
    }

    &.error {
      border-color: $accent-danger;
    }
  }

  &__svg {
    width: 22px;
    height: 22px;
    fill: $icons-high-contrast;

    &:hover {
      fill: $accent-primary;
    }
  }
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type='number'],
input[type='number']:hover,
input[type='number']:focus {
  appearance: none;
  -moz-appearance: textfield;
}
</style>
