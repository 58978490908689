import { TRAFFIC_LIGHTS } from './consts'

export const structure = {
  city: null,
  models: null,
  comp: TRAFFIC_LIGHTS,
  map: {},
  field: {
    type: 1,
    mode: 2
  },

  forwardLights: [],
  backwardLights: [],

  cloneForwardLights: [],
  cloneBackwardLights: [],

  forwardLightGaps: [],
  backwardLightGaps: [],

  cardFields: [],
  csd: [],
  loadingTreeNodeId: null,
  extraLayers: {
    graph: false
  },
  fields: {
    traffic_lights: {
      mode: {
        alias: {
          ru: 'Режим',
          en: 'Mode'
        },
        type: 'string'
      },
      status: {
        alias: {
          ru: 'Статус',
          en: 'Status'
        },
        type: 'string'
      }
    },
    detectors: {
      is_active: {
        alias: {
          ru: 'Статус',
          en: 'Status'
        },
        type: 'boolean'
      }
    },
    asudd_zpi: {
      angle: {
        alias: {
          ru: 'Угол',
          en: 'Angle'
        },
        type: 'string'
      }
    },
    asudd_pt: {
      grz: {
        alias: {
          ru: 'ГРЗ',
          en: 'GRZ'
        },
        type: 'string'
      },
      type: {
        alias: {
          ru: 'Тип ТС',
          en: 'Vehicle type'
        },
        type: 'string'
      },
      gps_time: {
        alias: {
          ru: 'GPS Время',
          en: 'GPS time'
        },
        type: 'datetime'
      }
    }
  }
}

export const nonClearableFields = ['city', 'map']
