import { additionalMapLayers } from '@/config/map/layer-styles'
import $store from 'STORE'

export const getPopupLayers = ({ controller, module }) => {
  const activeLayers = $store.state[module].activeLayers

  return activeLayers.reduce((acc, layerId) => {
    const layerStyle = controller?.styleConfig?.[layerId]
    const iconLayerId = `${layerId}-icon`

    if (layerStyle?.popup?.enabled) {
      if (layerStyle?.icon && !acc.includes(iconLayerId)) {
        acc.push(iconLayerId)
      }

      acc.push(layerId)

      additionalMapLayers.forEach(({ type }) => {
        acc.push(`${layerId}_${type}`)
      })
    } else {
      acc = acc.filter(l => l !== layerId || l !== iconLayerId)
    }

    return acc
  }, [])
}
