export const structure = {
  map: {},
  tree: [],
  fields: {},
  isSelectDisabled: false,
  layerNames: {},
  layerTypes: {},
  layerStyles: {},
  activeLayers: [],
  ptActiveLayers: [],
  selectedModelId: null,
  fullDataSet: {},
  objectsCount: {},
  // pt
  ptLayerNames: {},
  activeTsysCode: null,
  activeLineRouteItems: [],
  activeROEditingId: null,
  legendWidgetsOnMap: {}
}

export const nonClearableFields = [
  'layerStyles',
  'layerNames',
  'map',
  'legendWidgetsOnMap'
]
